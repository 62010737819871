<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-claims-view />

    <core-footer />

  </v-app>
</template>

<script>
export default {
  name: 'Claims',

  components: {
    CoreAppBar: () => import('../components/core/AppBar'),
    CoreDrawer: () => import('../components/core/Drawer'),
    CoreClaimsView: () => import('../components/core/ClaimsView'),
    CoreFooter: () => import('../components/core/Footer'),
  },

  data: () => ({
    expandOnHover: false,
  }),
}
</script>
